.admin-logout-button {
	position: absolute;
	margin-top: 5px;
	margin-left: 5px;
	cursor: pointer;
	color: #ff367a;
	top: 100px;
	left: 20px;
}

.table-container {
	width: 95%;
	margin: auto;
	display: flex;
	justify-content: center;
	height: 600px;
}

.table-container table {
	background: gray;
	border-radius: 5px;
}
.table-container th {
	border-bottom: 2px solid #dd137b;
}

.table-container td,
.table-container th {
	border-left: 1px solid dimgray;
	border-right: 1px solid dimgray;
	text-align: center;

	color: lightgray;
}

.ReactTable {
	width: 100%;
	color: white;
}

.rt-thead {
	background: dimgray;
}

.rt-th {
	border: 1px solid white;
}

.rt-td {
	border: 1px solid white;
	border-bottom: none;
}

.rt-tbody::-webkit-scrollbar {
	display: none;
}

.pagination-bottom {
	background: dimgray;
}

.export-button {
	position: absolute;
	right: 39px;
	top: 140px;
	color: white;
	font-weight: bold;
}
