.valign-wrapper {
  height: 100vh;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.3));
}

.valign-wrapper .container-background {
  opacity: 1;
}

.fix-this-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .auth-container {
    width: 100vw;
    max-width: 1400px;
  }

  .auth-left {
    width: 60%;
  }

  .auth-right {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.auth-top-bar {
  position: fixed;
  top: 0;
  height: 89px;
  background: black;
  width: 100%;
}
.top-bar-img {
  position: absolute;
  left: 100px;
  top: 20px;
  cursor: pointer;
}
.auth-container {
  display: flex;
}

.auth-left {
  width: 60%;
}
.auth-right {
  width: 40%;
}
.auth-text-container {
  padding: 0 10%;
  text-align: left;
}
.auth-text-first-line {
  text-align: left;
  font-family: Helvetica Neue, Helvetica, sans-serif;
  font-weight: bold;
  letter-spacing: 0.8px;
  color: #ffffff;
  opacity: 1;
}

.login-page-sub-header {
  font-family: 'Barlow Condensed', sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  /* font-variation-settings: 'wdth' 87.5; */
  /* font-stretch: condensed; */
  font-size: 27px;
  font-style: italic;
  color: white;
  text-shadow: 0px 3px 6px #00000096;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 10px;
}

.auth-text-header {
  text-align: left;
  font-family: 'League Gothic', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: 'wdth' 87.5;
  font-stretch: condensed;
  font-size: 48px;
  color: #dd137b;
  text-shadow: 0px 3px 6px #00000096;
  opacity: 1;
  margin-top: 10px;
}

.auth-text-paragraph {
  max-width: 690px;
  color: #ffffff;
  line-height: 25px;
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
  font-size: 17px;
  letter-spacing: 0.5px;
  text-shadow: 0px 3px 6px #00000096;

  b {
    font-weight: 500;
    font-size: 19px;
  }
}

.back-home {
  color: #dd137b;
  font-weight: bold;
}

.auth-left h4 {
  color: #dd137b;
}
.auth-details h4,
.auth-details p {
  text-shadow: 1px 1px black;
}
.auth-details .offset-s2 {
  background-color: rgba(0, 0, 0, 0.4);
}
.auth-details .has-background {
  background-color: rgba(0, 0, 0, 0.4);
}

.auth-button-row {
  padding: 0 10%;
}

.auth-button {
  width: 129px;
  height: 44px;
  line-height: 44px;
  color: black;
  font: Light 14px Helvetica Neue;
  font-family: Helvetica Neue, Helvetica, sans-serif;
  font-weight: lighter;
}

input[type='text']:not(.browser-default),
input[type='email']:not(.browser-default),
input[type='password']:not(.browser-default) {
  color: white !important;
}

.input-field label {
  color: white;
}

@media screen and (max-width: 1100px) {
  .auth-container {
    flex-wrap: wrap;
    position: absolute;
    top: 100px;
  }
  .auth-left {
    width: 100%;
  }
  .auth-right {
    width: 100%;
  }
  .auth-text-container {
    padding: 0 17%;
  }
  .auth-text-header {
    font-size: 32px;
  }
  .login-page-sub-header {
    font-size: 26px;
  }
}

@media screen and (max-width: 967px) {
  .fix-this-container {
    .auth-container {
      flex-direction: column;
    }
    .auth-left {
      width: 100%;
    }

    .auth-right {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media screen and (max-width: 650px) {
  .auth-top-bar {
    height: 60px;
  }
  .top-bar-img {
    margin: 5px auto;
    position: unset;
  }
  .auth-container {
    top: 60px;
  }
  .auth-right {
    margin-top: -10px;
  }
  .auth-text-container {
    padding: 0 10%;
  }
  .auth-text-header {
    font-size: 28px;
    margin-bottom: 0px;
  }
  .login-page-sub-header {
    font-size: 20px;
  }
  .auth-text-paragraph {
    font-size: 16px;
    line-height: unset;

    b {
      font-weight: 500;
      font-size: 17px;
    }
  }

  .auth-details h4 {
    font-size: 15px;
    margin: 10px 0 5px;
  }
  .auth-details p {
    margin: 0;
  }

  .auth-details .input-field {
    margin: 5px 0;
  }
  .auth-details .btn {
    margin-top: 0 !important;
    line-height: unset;
    width: unset;
    height: 30px;
  }
}
